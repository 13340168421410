.installerCardContainer {
  position: relative;
  background: #fff;
}
.installerCardContainer .dualPointGraphContainer {
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.installerCardContainer .installerCardDetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 120px 1fr 1fr 1fr;
}
.installerCardContainer .installerCardDetails .installerCardDetail {
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  font-weight: bold;
}
.installerCardContainer .installerCardDetails .installerCardDetail h2 {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  margin-bottom: 0.3rem;
  font-weight: normal;
}
.installerCardContainer .installerCardDetails .installerCardDetail--blue {
  color: #0d2a8a;
}
.installerCardContainer .installerCardDetails .installerCardDetail--red {
  color: #ff0000;
}
.installerCardContainer .installerCardDetails .installerCardDetail--orange {
  color: #FF8A00;
}
.installerCardContainer .installerCardDetails .installerCardDetail--yellow {
  color: #ffc72c;
}
.installerCardContainer .installerCardProfile {
  appearance: none;
  outline: none;
  border: none;
  background: none;
  margin: 0 auto;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  width: auto;
}
.installerCardContainer .installerCardProfile h1 {
  color: #0d2a8a;
  margin: 0 0 1rem 0;
  font-weight: normal;
  font-size: 2.2rem;
}
.installerCardContainer .installerCardProfile div {
  color: #006699;
  font-size: 1.2rem;
}
.installerCardContainer .installerCardButton {
  appearance: none;
  outline: none;
  background: #0d2a8a;
  border-radius: 1000px;
  border: none;
  color: rgba(255, 255, 255, 0.95);
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: -12.5px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 3px 6px 3px rgba(60, 64, 67, 0.16);
}
.installerCardContainer .installerCardButton--dashboard {
  left: 2rem;
}
.installerCardContainer .installerCardButton--actionPlan {
  right: 2rem;
}
.installerCardContainer--fixed {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 3px 6px 3px rgba(60, 64, 67, 0.16);
}
.installerCardContainer--fixed .dualPointGraphContainer {
  top: 10px;
}
.installerCardContainer--fixed .installerCardDetails {
  margin-top: 2.2rem;
}
.installerCardContainer--fixed .installerCardProfile {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.installerCardContainer--fixed .installerCardProfile h1 {
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
}
.installerCardContainer--fixed .installerCardProfile div {
  font-size: 1.2rem;
}
.installerCardContainer--dark {
  box-shadow: none;
  background: linear-gradient(149.61deg, rgba(0, 51, 102, 0.2) -0.52%, rgba(0, 51, 102, 0.08) 100%);
  border: none;
  margin-top: 0.5rem;
}
.installerCardContainer--dark .installerCardDetails {
  grid-template-columns: 1fr 1fr 120px 1fr 1fr;
}
.installerCardContainer--dark .installerCardDetail h2 {
  color: #5B91C8 !important;
}
.installerCardContainer--dark .installerCardDetail--blue {
  color: #0080FF !important;
}
.installerCardContainer--dark .installerCardProfile h1 {
  color: #0080FF !important;
}
.installerCardContainer--dark .installerCardProfile div {
  color: #5B91C8 !important;
}
@media print {
  .installerCardContainer--fixed {
    box-shadow: none;
    top: 0;
  }
}
