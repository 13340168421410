.offlineSyncContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.95);
}
.offlineSyncContainer h1 {
  color: rgba(255, 255, 255, 0.95);
}
.offlineSyncContainer .offlineSyncItem {
  line-height: 24px;
  margin: 1rem;
}
.offlineSyncContainer .offlineSyncItem i {
  vertical-align: bottom;
}
.offlineSyncContainer .offlineSyncItem--false i {
  animation: sync 2s infinite linear;
}
@keyframes sync {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
