h1.actionPlan {
  text-align: center;
  font-weight: normal;
  color: #0d2a8a;
}
.returnToSurvey {
  background: #eee;
  border: none;
  outline: none;
  appearance: none;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: #0d2a8a;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.pageNav {
  margin-top: 130px;
}
.pageNav button.pageTitle {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  background: none;
  cursor: pointer;
  text-align: left;
}
.pageNav button.pageTitle {
  padding: 2rem 0 0 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: #0d2a8a;
}
.pageNav button.pageTitle .pageCounter {
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  font-weight: bold;
  margin-bottom: 0.2rem;
  color: rgba(0, 0, 0, 0.6);
}
.pageNav button.pageTitle .sectionTitle {
  font-size: 1.5rem;
  display: inline;
  font-weight: normal;
}
.pageNav button.navigate {
  background: #eee;
  border-radius: 1000px;
  height: 36px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  width: auto;
  margin-top: 1.4rem;
}
.pageNav button.navigate i {
  vertical-align: bottom;
}
.pagesDisplay {
  position: fixed;
  top: 198px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 3rem 2rem;
}
.pagesDisplay .card {
  margin: 0px;
  max-height: 40px;
  text-align: center;
}
.actionPlanActions {
  text-align: right;
}
.actionPlanActions button {
  outline: none;
  appearance: none;
  padding: 0;
  margin: 0 0 0 1.5rem;
  border: none;
  background: none;
  color: rgba(0, 0, 0, 0.5);
}
.installerHeader {
  position: fixed;
  top: 80px;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  margin: -2rem -2rem 0 -2rem;
  background: #fafafa;
  border-bottom: 1px solid #eee;
  z-index: 2;
}
.installerHeader h1 {
  font-size: 1.7rem;
  color: #0d2a8a;
  font-weight: normal;
  margin: 0;
  letter-spacing: 0.02em;
  margin-bottom: 0.5rem;
}
.installerHeader div.address {
  letter-spacing: 0.02em;
  color: #1646e7;
  font-size: 1.2rem;
}
.installerHeader .directions {
  display: block;
  position: absolute;
  bottom: -1.9rem;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
  height: 36px;
  background: #0d2a8a;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18), 0 1px 3px 1px rgba(60, 64, 67, 0.36);
}
.installerHeader .directions i {
  line-height: 36px;
  color: rgba(255, 255, 255, 0.95);
}
.actionPlanMargin--sm {
  margin-top: 160px;
}
.actionPlanMargin--lg {
  margin-top: 200px;
}
.actionPlanItemBottom {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}
button.edit {
  appearance: none;
  outline: none;
  border: none;
  background: #ddd;
  border-radius: 4px;
  text-align: center;
  line-height: 24px;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
button.edit i {
  vertical-align: bottom;
  margin-right: 0.5rem;
}
button.edit:hover {
  background: #c4c4c4;
}
.actionPlanItemComplete {
  appearance: none;
  outline: none;
  border: none;
  background: #33A532;
  color: rgba(255, 255, 255, 0.95);
  line-height: 24px;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}
.actionPlanItemComplete i {
  vertical-align: bottom;
  margin-right: 0.5rem;
}
.actionPlanItemComplete:hover {
  background: #277e26;
}
@media print {
  .hideOnPrint {
    display: none;
  }
  .displayOnPrint {
    display: block;
  }
  .main {
    margin: 0 !important;
  }
  .installerCardContainer {
    position: absolute;
  }
  .actionPlanMargin {
    margin-top: 160px;
  }
}
