.upload__dropzone {
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 1.3rem;
  padding: 1rem 0;
  cursor: pointer;
}
.upload__dropzone:hover {
  border-color: #003366;
}
.fileList__fileContainer {
  background: #eee;
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
  position: relative;
}
.fileList__filename {
  display: inline-block;
  line-height: 4em;
  font-size: 9pt;
  margin-left: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(52%);
}
.fileList__image {
  display: inline-block;
  vertical-align: top;
  width: 4em;
}
.fileList__progressContainer {
  position: relative;
  width: 4em;
  height: 4em;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
}
.fileList__progressContainer .fileList__dialContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
}
.fileList__progressContainer .fileList__dialContainer .fileList__wedge {
  background: #003366;
  height: 100%;
  width: 100%;
}
.fileList__progressContainer .fileList__dialContainer--container1 {
  left: 50%;
}
.fileList__progressContainer .fileList__dialContainer--container1 .fileList__wedge {
  border-radius: 0 4em 4em 0;
  left: 0;
  transform-origin: 0 50%;
  transform: rotateZ(-180deg);
}
.fileList__progressContainer .fileList__dialContainer--container2 {
  left: 0;
}
.fileList__progressContainer .fileList__dialContainer--container2 .fileList__wedge {
  border-radius: 4em 0 0 4em;
  transform-origin: 2em 2em;
  transform: rotateZ(-180deg);
}
.fileList__progressContainer::after {
  content: "";
  background: #eee;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.fileList__delete {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  padding: 4px;
  width: 36px;
  transform: translateY(-50%);
  outline: none;
  cursor: pointer;
}
.fileList__delete:hover {
  background: rgba(0, 0, 0, 0.3);
}
.fileListLink {
  color: #006699;
}
.fileListLink:hover {
  color: #00aaff;
}
