.waitingToSync {
  position: absolute;
  right: 5.5rem;
  top: 50%;
  height: 24px;
  transform: translateY(-50%);
}
.waitingToSync .syncCountBubble {
  position: absolute;
  background: #ff0000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  top: -6px;
  right: -6px;
}
