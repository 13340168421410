.tabSelectorContainer {
  border: 1px solid #0d2a8a;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  height: 30px;
}
.tabSelectorContainer .tabSelectorButton {
  border: none;
  background: none;
  outline: none;
  appearance: none;
  margin: 0px;
  padding: 0px 2rem;
  line-height: 30px;
  vertical-align: top;
  cursor: pointer;
}
.tabSelectorContainer .tabSelectorButton--active {
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
}
.tabSelectorContainer .tabSelectorButton--inactive {
  color: #0d2a8a;
}
