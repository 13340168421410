.datepickerContainer {
  position: relative;
  min-height: 40px;
}
.datepickerContainer .datepicker--top {
  bottom: calc(130%);
}
.datepickerContainer .datepicker {
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.9);
  z-index: 100;
  position: absolute;
  background: #fff;
  left: 0;
}
.datepickerContainer .datepicker .datepickerTriangle {
  width: 0;
  height: 0;
  left: 33px;
  position: absolute;
}
.datepickerContainer .datepicker .datepickerTriangle--top {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #eee;
  top: -8px;
}
.datepickerContainer .datepicker .datepickerTriangle--bottom {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  bottom: -8px;
}
.datepickerContainer .datepicker .datepickerHeader {
  background: #eee;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
  font-size: 1.2rem;
  border-radius: 4px 4px 0 0;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerMonth {
  text-align: center;
  line-height: 3rem;
  font-weight: bold;
  font-size: 1.3rem;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse {
  border: none;
  outline: none;
  background: none;
  padding: 0px;
  margin: 0px;
  position: absolute;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse i {
  line-height: 3rem;
  width: 3rem;
  height: 3rem;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse:hover {
  color: rgba(0, 0, 0, 0.7);
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse--prev {
  left: 0;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse--next {
  right: 0;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerDaysOfWeek .datepickerDayOfWeek {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  padding: 0.3rem;
}
.datepickerContainer .datepicker .datepickerBody {
  padding: .4rem;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay {
  display: inline-block;
  vertical-align: bottom;
  background: none;
  border: none;
  padding: 0.3rem;
  outline: none;
  position: relative;
  color: rgba(0, 0, 0, 0.3);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay .datepickerDay--padding {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay--currentMonth {
  color: rgba(0, 0, 0, 0.9);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek button.datepickerDay {
  cursor: pointer;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek button.datepickerDay .datepickerDay--padding {
  border-radius: 50%;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek button.datepickerDay:hover:not(.datepickerDay--selected) .datepickerDay--padding {
  background: rgba(0, 0, 0, 0.1);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay--today .datepickerDay--padding {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.1);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay--selected .datepickerDay--padding {
  font-weight: bold;
  background: #003366;
  color: rgba(255, 255, 255, 0.95);
}
