.cardInstaller {
  text-align: center;
  padding: 3rem 0;
  margin: -2rem -2rem 0 -2rem;
  background: #fafafa;
  position: relative;
  border-bottom: 1px solid #eee;
}
.cardInstaller h1 {
  font-size: 2.4rem;
  color: #0d2a8a;
  font-weight: normal;
  margin: 0;
  letter-spacing: 0.02em;
  margin-bottom: 1rem;
}
.cardInstaller div.address {
  letter-spacing: 0.02em;
  color: #1646e7;
  font-size: 1.6rem;
}
.cardInstaller .directions {
  display: block;
  position: absolute;
  bottom: -1.9rem;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
  height: 36px;
  background: #0d2a8a;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18), 0 1px 3px 1px rgba(60, 64, 67, 0.36);
}
.cardInstaller .directions i {
  line-height: 36px;
  color: rgba(255, 255, 255, 0.95);
}
.cardHistory .history {
  display: grid;
  grid-template-columns: minmax(80px, 1fr) minmax(90px, 1fr) minmax(60px, 1fr) minmax(40px, 0.4fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
}
.cardHistory .history .dualPointGraphContainer {
  position: relative;
}
.cardHistory .history .cardHistoryDetail {
  text-align: left;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  font-weight: bold;
  padding-top: 1.2rem;
}
.cardHistory .history .cardHistoryDetail h2 {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  margin-bottom: 0.3rem;
  font-weight: normal;
}
.cardHistory .history .cardHistoryDetail--right {
  text-align: right;
}
.cardHistory .history .cardHistoryDetail--blue {
  color: #0d2a8a;
}
.cardHistory .history .cardHistoryDetail--red {
  color: #ff0000;
}
.cardHistory .history .cardHistoryDetail--orange {
  color: #FF8A00;
}
.cardHistory .history .cardHistoryDetail--yellow {
  color: #ffc72c;
}
.cardHistory .history .cardHistoryDivider {
  background: #eee;
  width: 1px;
  height: 40px;
  margin-top: 10px;
}
