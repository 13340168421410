.card-item {
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  font-weight: normal;
  text-align: left;
}
.card-item h2 {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  margin-bottom: 0.3rem;
  font-weight: normal;
}
