/* NOTE: This line below is required for MD icons to appear */
@import url('https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css');
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
html {
  font-size: 62.5%;
}
root,
html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #0d2a8a;
  color: rgba(0, 0, 0, 0.9);
  overflow-y: scroll;
}
h1,
h2 {
  color: #0d2a8a;
  margin: 0;
  font-weight: normal;
}
h2 {
  margin-top: 2rem;
}
.material-icons {
  font-feature-settings: "liga" 1;
}
/* Start utilities */
.hidden,
.grid.hidden {
  display: none;
}
.text-center {
  text-align: center;
}
.f-right {
  float: right;
}
.inline-link {
  color: #0d2a8a;
}
.full-width {
  min-width: 100%;
}
/* NOTE: The 20 in the naming here is to represent 20 as the pixel value in case more utility classes want to leverage this convention */
.pad-t-20 {
  padding-top: 1.25em;
}
.pad-b-20 {
  padding-bottom: 1.25em;
}
.push-b-0 {
  margin-bottom: 0;
}
/* End utilities */
/* Start header bar */
header {
  height: 60px;
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  font-size: 2em;
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0px auto;
  z-index: 100;
  text-align: center;
}
.logo {
  height: 36px;
  margin: 12px 2rem;
}
/* End header bar */
/* Start main content area */
.main {
  /* NOTE: This syntax is shorthand for top value of 0, side values of 20px and bottom value of 10px */
  padding: 2rem;
  background: #fff;
  box-sizing: border-box;
  margin-top: 60px;
  font-size: 1.4rem;
  position: relative;
}
.contentContainer {
  max-width: 1024px;
  margin: 0 auto;
}
.contentContainer .installer-list {
  padding-top: 2rem;
}
.contentContainer--login,
.contentContainer--forgot {
  max-width: 640px;
}
.primary--heading {
  color: #0d2a8a;
}
.linked-card--container {
  text-decoration: none;
}
/* Start dashboard */
.dashboard--row {
  margin-bottom: 10px;
}
.dashboard--row .dashboard--row__title {
  background: #006699;
  color: rgba(255, 255, 255, 0.95);
  padding: 0.5em;
  position: relative;
  top: 45px;
}
.dashboard--row .dashboard--row__title h2 {
  font-weight: 100;
  margin: 0;
}
.dashboard--row .dashboard--row__title .expander-menu {
  color: rgba(255, 255, 255, 0.95);
  position: relative;
  top: -3.5rem;
}
.dashboard--row .dashboard--row__content {
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  min-height: 100px;
  /* These rules are for horizontal scrolling containers */
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  min-width: 100%;
  -webkit-overflow-scrolling: touch;
  /* Horizontal row info container (data column) */
}
.dashboard--row .dashboard--row__content .dashboard--row__info {
  display: inline-block;
  max-width: 20%;
  padding: 3rem 6rem;
  text-align: center;
}
.dashboard--row .dashboard--row__content .dashboard--row__info .primary--info__number {
  font-size: 1.6rem;
}
#long-menu p {
  color: #0d2a8a;
  font-size: 1.2rem;
}
/* End dashboard */
/* Start survey list */
.contentContainer--survey-list a {
  text-decoration: none;
}
.contentContainer--survey-list .expander-menu {
  position: absolute;
  right: 2rem;
  margin-top: -5.75rem;
}
.survey-draft {
  color: #A7A7A7;
}
.survey-completed {
  color: #33A532;
}
/* End survey list */
/* Start reports */
.contentContainer--reports {
  /* NOTE: This is for when all columns should be displayed */
}
.contentContainer--reports .reports-table .table-headings--container th {
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  text-align: left;
}
.contentContainer--reports .reports-table .table-headings--container th:nth-of-type(2) {
  display: none;
}
.contentContainer--reports .reports-table tr > th,
.contentContainer--reports .reports-table tbody > tr {
  width: calc(100% / 5);
}
.contentContainer--reports .reports-table td:nth-of-type(2) {
  display: none;
}
.contentContainer--reports .reports-table tbody td {
  padding: 1rem;
}
.contentContainer--reports .full-table .table-headings--container th:nth-of-type(2) {
  display: table-cell;
}
.contentContainer--reports .full-table tr > th,
.contentContainer--reports .full-table tbody > tr {
  width: calc(100% / 6);
}
.contentContainer--reports .full-table td:nth-of-type(2) {
  display: table-cell;
}
.contentContainer--reports .reports-grid .card-title-container {
  /* NOTE: This rule is to help provide a consistent card heading height */
}
.contentContainer--reports .reports-grid .card-title-container h3 {
  min-height: 50px;
}
/* End reports */
/* End main content area */
/* Start media queries */
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .grid {
      display: grid;
      grid-gap: 3rem;
    }
    .grid-gap-sm {
      grid-gap: 1rem;
    }
    .grid-1-1 {
      grid-template-columns: 1fr 1fr;
    }
    .grid-1-2 {
      grid-template-columns: 1fr 2fr;
    }
    .grid-one-third {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-1-3 {
      grid-template-columns: 1fr 3fr;
    }
    .grid-2-1 {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
    }
    .dashboard--row {
      max-width: 728px;
    }
    .survey-category--container {
      position: relative;
      top: 2px;
    }
    .survey-category--container .survey-category-text {
      color: #0d2a8a;
      font-size: 1.6rem;
      vertical-align: super;
    }
    .survey-category--container .btn-arrow:last-of-type {
      float: right;
    }
    .vertical-tabs-container {
      background-color: rgba(255, 255, 255, 0.95);
      min-height: 100%;
      width: 25%;
    }
    .vertical-tabs-container .vertical-tab-link {
      background-color: #c4c4c4;
      border: 1px solid rgba(255, 255, 255, 0.95);
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;
      display: block;
      outline: none;
      padding: 1rem;
      text-align: left;
      transition: 0.3s;
      width: 100%;
    }
    .vertical-tabs-container .vertical-tab-link:hover {
      background-color: #1138b9;
      color: rgba(0, 0, 0, 0.9);
    }
    .vertical-tabs-container .vertical-tab-link.active {
      background-color: #0d2a8a;
      color: rgba(255, 255, 255, 0.95);
    }
    .vertical-tabs-container .vertical-tab-link .survey-subcategory-text {
      font-size: 1.6rem;
    }
    .rwt__tablist[aria-orientation="vertical"] {
      border-right: none !important;
    }
    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
      display: none;
    }
    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="false"]:hover {
      background-color: #1138b9;
      color: rgba(0, 0, 0, 0.9);
    }
    .rwt__tablist[aria-orientation="vertical"] .rwt__tab:focus {
      background-color: #0d2a8a;
      color: rgba(255, 255, 255, 0.95);
    }
    .survey-questions--container {
      background: rgba(255, 255, 255, 0.95);
      border-left: none;
      min-height: 100%;
      min-width: 75%;
      margin-left: -1rem;
    }
    .survey-questions--container .survey-question {
      padding: 0 2rem 4rem 2rem;
    }
    .survey-questions--container .survey-question label {
      margin-left: 0px;
    }
    .survey-questions--container .survey-question .action-plan--container,
    .survey-questions--container .survey-question .action-plan--label {
      position: relative;
      top: -5px;
    }
    .survey-questions--container .survey-question .radio {
      display: inline-block;
      position: relative;
      top: 2px;
    }
    .survey-questions--container .survey-question .radio label {
      position: relative;
      padding-right: 5px;
    }
    .survey-questions--container .switch {
      border: none;
      margin-bottom: 0px;
      padding-left: 0px;
    }
    .survey-questions--container .switch .toggle__wrapper {
      margin-left: 0px;
    }
    .survey-questions--container .binary-yes-label {
      position: relative;
      top: -3.25rem;
      left: 110px;
    }
    .survey-questions--container .notes-container .textarea {
      border: none;
      padding-right: 0.75rem;
      padding-left: 0px;
    }
    .survey-questions--container .notes-container--hidden {
      opacity: 0;
      max-height: 0px;
      transition: all 0.5s linear;
      overflow: hidden;
    }
    .survey-questions--container .notes-container--visible {
      opacity: 1;
      max-height: 300px;
      transition: all 0.5s linear;
    }
  }
  @media screen and (min-width: 64em) {
    .dashboard--row {
      max-width: 984px;
    }
    .contentContainer--reports .reports-table .table-headings--container th:nth-of-type(2) {
      display: table-cell;
    }
    .contentContainer--reports .reports-table tr > th,
    .contentContainer--reports .reports-table tbody > tr {
      width: calc(100% / 6);
    }
    .contentContainer--reports .reports-table td:nth-of-type(2) {
      display: table-cell;
    }
  }
  @media screen and (min-width: 64.063em) {
    .dashboard--row {
      max-width: 1024px;
    }
    .contentContainer--survey-list .expander-menu {
      position: relative;
    }
  }
}
