.contentContainer--releaseNotes {
  font-size: 1.8rem;
}
.contentContainer--releaseNotes h2 {
  text-align: center;
  margin: 4rem 0;
  font-size: 3rem;
}
.contentContainer--releaseNotes h3 {
  color: rgba(0, 0, 0, 0.75);
  font-size: 3rem;
}
.contentContainer--releaseNotes h5 {
  text-align: center;
  margin-top: -2.5rem;
  margin-bottom: 4rem;
}
.contentContainer--releaseNotes .releaseNotes__release:not(:first-of-type) {
  margin-top: 8rem;
}
.contentContainer--releaseNotes .releaseNotes__bugfix {
  line-height: 24px;
}
.contentContainer--releaseNotes .releaseNotes__bugfix i {
  vertical-align: bottom;
  color: #33A532;
  margin-right: 1rem;
}
