.btn {
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 1.5rem 0px;
  text-align: center;
  width: 100%;
  outline: none;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.6rem;
  appearance: none;
}
.btn-yellow {
  background: #ffc72c;
  color: #0d2a8a;
}
.btn-secondary {
  color: rgba(0, 0, 0, 0.9);
}
.btn-primary {
  background: #0d2a8a;
}
.btn-primary:hover {
  background: #006699;
}
.btn-success {
  background: #33A532;
}
.btn-success:hover {
  background: #45c644;
}
.btn-arrow {
  background-color: rgba(255, 255, 255, 0.95);
  display: inline;
  max-width: 60px;
  padding: 1rem;
}
.btn-arrow i {
  color: #0d2a8a;
}
.btn-agree {
  padding: 2rem 5rem;
  width: auto;
  margin: 0 auto;
  font-size: 2.1rem;
  background: #33A532;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.btn-agree:hover {
  background: #277e26;
}
.btn:disabled {
  background: #A7A7A7;
  cursor: not-allowed;
  color: #fff;
}
button.clear {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  border-radius: 1000px;
  border: none;
  appearance: none;
  outline: none;
  background: #eee;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 0.5rem 1rem;
}
.btn-link {
  width: auto;
  padding: 0px;
  margin: 0px;
  background: none;
  color: #0d2a8a;
  text-decoration: underline;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-top: 3rem;
}
