.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader .foreground,
.loader .background {
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader .foreground {
  fill: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.loader .background {
  fill: rgba(0, 0, 0, 0.4);
  animation-name: pulse;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes pulse {
  0% {
    height: 60px;
    opacity: 1;
  }
  100% {
    height: 100px;
    opacity: 0;
  }
}
