.actionPlanButton {
  background: #eee;
  border: none;
  outline: none;
  appearance: none;
  position: absolute;
  right: 1.5rem;
  top: 3.5rem;
  border-radius: 100px;
  padding: 0.5rem 70px 0.5rem 1rem;
  font-size: 1.2rem;
  color: #003366;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.dualPointGraphContainer {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 50%;
}
.dualPointGraphContainer svg.circle {
  filter: drop-shadow(-2px 0px 3px rgba(0, 0, 0, 0.2));
}
.dualPointGraphContainer svg.circle circle {
  transition: all 500ms;
}
.dualPointGraphContainer .overallPoints,
.dualPointGraphContainer .goldStandardPoints {
  position: absolute;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 1rem;
  width: 50px;
  letter-spacing: 0.04em;
}
.dualPointGraphContainer .overallPoints {
  right: 4rem;
  top: 4.5rem;
  text-align: right;
  color: #003366;
}
.dualPointGraphContainer .goldStandardPoints {
  right: 9.3rem;
  top: 4.5rem;
  text-align: right;
  color: #F5C211;
}
.dualPointGraphContainer--dark {
  background: none;
}
