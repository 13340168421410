.noData {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 4rem 8rem;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  line-height: 3rem;
}
.noDataExtended {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 8rem 0;
  text-align: center;
  letter-spacing: 0.02em;
}
.noDataExtended .noDataExtendedHelper {
  font-size: 2rem;
  color: #0d2a8a;
  margin-bottom: 0.5rem;
}
.noDataExtended .noDataExtendedSubHelper {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.5);
}
.noDataExtended button,
.noDataExtended a {
  width: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 0 auto;
  margin-top: 2.5rem;
  display: inline-block;
}
