@import url(https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css);
.nav__toggle {
  position: absolute;
  right: 2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 0 -1rem;
}
.nav__toggle i {
  color: #fff;
}
.nav__container {
  background: #0d2a8a;
  padding: 1rem 2rem;
  min-width: 240px;
  position: fixed;
  top: 6rem;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  opacity: 1;
  z-index: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  text-align: center;
}
.nav__container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav__container ul li {
  display: block;
  text-align: left;
}
.nav__container ul li button {
  background: none;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.95);
  font-size: 1.6rem;
  padding: 1.5rem 0;
  cursor: pointer;
}
.nav__container ul li button:hover {
  color: rgba(204, 204, 204, 0.95);
}
.nav__container a:not(.button) {
  font-size: medium;
  font-size: initial;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  line-height: 2em;
}
.nav__container a:hover {
  color: #006699;
}
.nav__container--hidden {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.semver {
  font-size: 1.1rem;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 0.5);
}
.nav__stats {
  position: absolute;
  left: 2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 0 -1rem;
  margin-bottom: 10rem;
  z-index: 1000;
}
.nav__stats i {
  color: #fff;
}
.csvDownloadButton {
  left: auto;
  right: 2rem;
  top: -1rem;
}
.statsContainer {
  background: #001429;
  padding: 4rem 2rem 2rem 2rem;
  position: fixed;
  left: -100%;
  overflow-y: auto;
}
.statsContainer .statsContainerMaxWidth {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
}
.statsContainer h2 {
  font-size: 2rem;
  color: #a7c4e2;
  font-weight: normal;
  margin-top: 6rem;
  margin-bottom: 3rem;
}
.statsContainer h2 div {
  font-size: 1.4rem;
  color: #5B91C8;
  margin-bottom: 0.5rem;
}
.statsContainer h3 {
  font-size: 1.4rem;
  font-weight: normal;
  color: #5B91C8;
  text-align: left;
}
.statsContainer--expanded {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.reportDisplaySwitch {
  background: #0d2a8a;
  border: none;
  margin: 0;
  padding: 0.5rem 1rem;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 5px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.95);
  float: right;
}
.reportDisplaySwitch i {
  vertical-align: bottom;
  margin-left: 1rem;
}
.bdsCardHeader {
  text-align: left;
  font-size: 1.4rem;
  color: #5B91C8;
}
.bdsCardStatsContainer {
  float: right;
  font-size: 1.2rem;
}
.bdsCardStatsContainer .bdsCardStats {
  margin-left: 2rem;
}
.bdsCardStatsContainer .bdsCardStats--goldStandard {
  color: #ffc72c;
}
.bdsCardStatsContainer .bdsCardStats--onTrack {
  color: #0080FF;
}
.bdsCardStatsContainer .bdsCardStats--installersSurveyed {
  color: #2FFF69;
}
.bdsCardStatsContainer .bdsCardStats--revisits {
  color: #A32FFF;
}
.progressBarContainer {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 1rem;
}
.progressBarContainer .progressBar {
  height: 5px;
  position: absolute;
  left: 0;
  top: 0;
  background: #0080FF;
  width: 0px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.progressBarContainer .progressBar--goldStandard {
  background: #ffc72c;
}
.progressBarContainer .progressBar--onTrack {
  background: #0080FF;
}
.progressBarContainer .progressBar--installersSurveyed {
  background: #2FFF69;
}
.progressBarContainer .progressBar--revisits {
  background: #A32FFF;
}
.tabSelectorContainer {
  border: 1px solid #0d2a8a;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  height: 30px;
}
.tabSelectorContainer .tabSelectorButton {
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  margin: 0px;
  padding: 0px 2rem;
  line-height: 30px;
  vertical-align: top;
  cursor: pointer;
}
.tabSelectorContainer .tabSelectorButton--active {
  background: #0d2a8a;
  color: rgba(0, 0, 0, 0.9);
}
.tabSelectorContainer .tabSelectorButton--inactive {
  color: #5B91C8;
}
.analytic .card,
.breakdownCard.card {
  border: 1px solid rgba(0, 0, 0, 0);
}
.analytic--selected .card,
.breakdownCard--selected.card {
  border: 1px solid #0080FF;
}
.clearStat {
  border: 1px solid #0080FF;
  border-radius: 5px;
  background: #0080FF;
  color: rgba(0, 0, 0, 0.9);
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  margin: 0px;
  padding: 3px 1rem;
  line-height: 30px;
  vertical-align: top;
  cursor: pointer;
  line-height: 24px;
  float: right;
  max-width: 1024px;
}
.clearStat i {
  vertical-align: bottom;
  margin-left: 1rem;
}
.clearStat--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 3rem 1rem 1rem 1rem;
  background: linear-gradient(to bottom, rgba(0, 20, 41, 0), #001429);
}
.clearStat--fixed button.clearStat {
  width: 100%;
  padding: 1.5rem 0;
  font-size: 1.8rem;
  float: none;
  margin-left: -1.5rem;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
}
.cardStatPercentage {
  font-size: 1.2rem;
  position: absolute;
  top: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #A0A0A0;
  line-height: 24px;
}
.cardStatPercentage i {
  vertical-align: bottom;
  display: none;
}
.cardStatPercentage--increase {
  color: #2FFF69;
}
.cardStatPercentage--decrease {
  color: #FF2F2F;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loader .foreground,
.loader .background {
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loader .foreground {
  fill: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.loader .background {
  fill: rgba(0, 0, 0, 0.4);
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-duration: 900ms;
          animation-duration: 900ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes pulse {
  0% {
    height: 60px;
    opacity: 1;
  }
  100% {
    height: 100px;
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    height: 60px;
    opacity: 1;
  }
  100% {
    height: 100px;
    opacity: 0;
  }
}

.card {
  padding: 1.2rem 1.5rem;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
  background: none;
  margin: 2rem 0;
  display: block;
  border: 1px solid #eee;
  outline: none;
  position: relative;
  transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1), width 235ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 5px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  page-break-inside: avoid;
}
.card .cardPointTitleContainer {
  text-align: center;
  text-transform: uppercase;
  font-size: 5.6rem;
  margin: 3rem 0 6rem 0;
  color: #0d2a8a;
}
.card .cardPointTitleContainer .cardPointTitle {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
}
.card .cardPriorityTitleContainer {
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}
.card .cardPriorityTitleContainer .cardPriorityTitle {
  background: #eee;
  margin: -1.3rem -1.6rem 0 -1.6rem;
  border-radius: 5px 5px 0 0;
  padding: 1rem 0;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
}
.card .cardPriorityTitleContainer .cardPriorityItemCount {
  font-size: 7rem;
  margin-top: 2rem;
}
.card .cardPriorityTitleContainer--high .cardPriorityTitle {
  background: #ff0000;
  color: #700000;
}
.card .cardPriorityTitleContainer--high .cardPriorityItemCount {
  color: #ff0000;
}
.card .cardPriorityTitleContainer--medium .cardPriorityTitle {
  background: #FF8A00;
  color: #703d00;
}
.card .cardPriorityTitleContainer--medium .cardPriorityItemCount {
  color: #FF8A00;
}
.card .cardPriorityTitleContainer--low .cardPriorityTitle {
  background: #ffc72c;
  color: #9c7300;
}
.card .cardPriorityTitleContainer--low .cardPriorityItemCount {
  color: #ffc72c;
}
.card .cardDivider {
  border-top: 1px solid #eee;
  margin: 2rem -1.6rem;
  position: relative;
}
.card .cardDivider .cardDividerTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  padding: 0 0.8rem;
}
.card.card--goldStandard {
  border: 1px solid #ffc72c;
}
.card.card--goldStandard .goldStandardIcon {
  position: absolute;
  top: -12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.card.card--goldStandard .goldStandardIcon svg {
  -webkit-filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}
.card.card--goldStandard .cardPointTitleContainer {
  color: #ffc72c;
}
.card.card--goldStandard .cardDivider {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
a.card:hover,
button.card:hover {
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 4px 12px 1px rgba(60, 64, 67, 0.16);
}
.card--dark {
  box-shadow: none;
  background: linear-gradient(149.61deg, rgba(0, 51, 102, 0.2) -0.52%, rgba(0, 51, 102, 0.08) 100%);
  border: none;
  margin-top: 0.5rem;
}
.cardStatPoints {
  text-align: center;
  font-size: 2.6rem;
  margin: 1rem 0;
}
.cardStatPoints--overall {
  color: #0080FF;
}
.cardStatPoints--goldStandard {
  color: #ffc72c;
}
.cardStatPoints--small {
  font-size: 1.2rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  color: #A0A0A0;
}
.cardStatPoints--small .cardStatPercentage {
  position: relative;
  top: auto;
}
.cardStatPoints--small.goldStandard {
  top: 0;
  left: 4.5rem;
  color: #ffc72c;
}
.cardStatPoints--small.overall {
  top: 0;
  right: 0rem;
  left: auto;
  color: #0080FF;
}
.card--button {
  cursor: pointer !important;
}
.actionPlanCardQuestion--high {
  color: #ff0000;
}
.actionPlanCardQuestion--medium {
  color: #FF8A00;
}
.actionPlanCardQuestion--low {
  color: #ffc72c;
}
.cardPriorityHighlight {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  border-radius: 5px 0 0 5px;
  z-index: 1;
}
.cardPriorityHighlight--high {
  background: #ff0000;
}
.cardPriorityHighlight--medium {
  background: #FF8A00;
}
.cardPriorityHighlight--low {
  background: #ffc72c;
}

.actionPlanButton {
  background: #eee;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  position: absolute;
  right: 1.5rem;
  top: 3.5rem;
  border-radius: 100px;
  padding: 0.5rem 70px 0.5rem 1rem;
  font-size: 1.2rem;
  color: #003366;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.dualPointGraphContainer {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #fff;
  border-radius: 50%;
}
.dualPointGraphContainer svg.circle {
  -webkit-filter: drop-shadow(-2px 0px 3px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(-2px 0px 3px rgba(0, 0, 0, 0.2));
}
.dualPointGraphContainer svg.circle circle {
  transition: all 500ms;
}
.dualPointGraphContainer .overallPoints,
.dualPointGraphContainer .goldStandardPoints {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-weight: bold;
  font-size: 1rem;
  width: 50px;
  letter-spacing: 0.04em;
}
.dualPointGraphContainer .overallPoints {
  right: 4rem;
  top: 4.5rem;
  text-align: right;
  color: #003366;
}
.dualPointGraphContainer .goldStandardPoints {
  right: 9.3rem;
  top: 4.5rem;
  text-align: right;
  color: #F5C211;
}
.dualPointGraphContainer--dark {
  background: none;
}

/* Variable definitions */
.input-container {
  display: block;
  border-radius: 4px;
  margin: 0 0 1.5rem 0;
  position: relative;
  height: 5.4rem;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.input-container .inputHelper {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #A7A7A7;
}
label {
  font-size: 1.6rem;
}
label.note {
  margin-top: 4rem;
  margin-bottom: 3rem;
}
label,
.label {
  display: block;
  margin-bottom: 0.5rem;
}
.input-container.input-focused .label,
.input-container.input-filled .label {
  top: 0px;
  font-size: 1.2rem;
  line-height: 2.4rem;
  transition: all 0.1s ease;
}
.input-container.input-focused {
  border: 1px solid #0d2a8a;
}
input {
  border: none;
  outline: none;
  background: none;
  position: relative;
  width: 100%;
  font-size: 1.6rem;
  height: 100%;
  top: 1px;
  text-indent: 1.5rem;
}
select {
  border: none;
  outline: none;
  background: none;
  position: relative;
  width: 100%;
  font-size: 1.6rem;
  height: 100%;
  padding: 0 1.5rem;
  top: 1px;
  -webkit-appearance: none;
          appearance: none;
}
.inputContainer--note {
  cursor: text;
  min-height: 10rem;
}
.inputContainer--note textarea {
  position: relative;
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
          appearance: none;
  font-size: 1.6rem;
}
.noteToggle {
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -12px;
  right: 1.5rem;
  height: 24px;
  border-radius: 500px;
  background: #eee;
  color: rgba(0, 0, 0, 0.45);
  line-height: 24px;
  padding: 0 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}
.noteToggle i {
  vertical-align: bottom;
}
.inputType--boolean button {
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
          appearance: none;
  height: 5.4rem;
  width: 50%;
  background: #eee;
  box-sizing: border-box;
}
.inputType--boolean button:first-of-type {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.inputType--boolean button:first-of-type.selected {
  background: #E00E0E;
  color: rgba(255, 255, 255, 0.95);
}
.inputType--boolean button:last-of-type {
  border-radius: 0 5px 5px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.inputType--boolean button:last-of-type.selected {
  background: #33A532;
  color: rgba(255, 255, 255, 0.95);
}
.inputType--slider {
  -webkit-appearance: none;
          appearance: none;
  width: 100%;
  background: none;
  outline: none;
  height: 20px;
}
.inputContainer--slider {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 20px;
}
.inputContainer--slider .label {
  position: absolute;
  top: -2rem;
  font-size: 1.1rem;
}
.inputContainer--slider .sliderBackgroundContainer {
  position: absolute;
  top: 50%;
  left: 1rem;
  right: 1rem;
  height: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inputContainer--slider .sliderBackgroundContainer .sliderBackground {
  background: #eee;
  width: 33.333%;
  float: left;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.inputContainer--slider .sliderBackgroundContainer .sliderBackground .colorBar {
  height: 100%;
}
.inputContainer--slider .sliderBackgroundContainer .sliderBackground:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.inputContainer--slider .sliderBackgroundContainer .sliderBackground:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.inputContainer--slider .sliderBackgroundContainer .sliderBackground--low .colorBar {
  background-color: #ffc72c;
}
.inputContainer--slider .sliderBackgroundContainer .sliderBackground--med .colorBar {
  background-color: #FF8A00;
}
.inputContainer--slider .sliderBackgroundContainer .sliderBackground--hi .colorBar {
  background-color: #FF0D0D;
}
.inputContainer--slider .sliderLabelContainer {
  position: absolute;
  bottom: -5px;
  width: 100%;
}
.inputContainer--slider .sliderLabelContainer div {
  display: inline-block;
  position: absolute;
  font-size: 0.9rem;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.75);
}
.inputType--slider::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  width: 28px;
  height: 28px;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.inputType--slider::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.upload__dropzone {
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 1.3rem;
  padding: 1rem 0;
  cursor: pointer;
}
.upload__dropzone:hover {
  border-color: #003366;
}
.fileList__fileContainer {
  background: #eee;
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
  position: relative;
}
.fileList__filename {
  display: inline-block;
  line-height: 4em;
  font-size: 9pt;
  margin-left: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(52%);
}
.fileList__image {
  display: inline-block;
  vertical-align: top;
  width: 4em;
}
.fileList__progressContainer {
  position: relative;
  width: 4em;
  height: 4em;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
}
.fileList__progressContainer .fileList__dialContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
}
.fileList__progressContainer .fileList__dialContainer .fileList__wedge {
  background: #003366;
  height: 100%;
  width: 100%;
}
.fileList__progressContainer .fileList__dialContainer--container1 {
  left: 50%;
}
.fileList__progressContainer .fileList__dialContainer--container1 .fileList__wedge {
  border-radius: 0 4em 4em 0;
  left: 0;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotateZ(-180deg);
          transform: rotateZ(-180deg);
}
.fileList__progressContainer .fileList__dialContainer--container2 {
  left: 0;
}
.fileList__progressContainer .fileList__dialContainer--container2 .fileList__wedge {
  border-radius: 4em 0 0 4em;
  -webkit-transform-origin: 2em 2em;
          transform-origin: 2em 2em;
  -webkit-transform: rotateZ(-180deg);
          transform: rotateZ(-180deg);
}
.fileList__progressContainer::after {
  content: "";
  background: #eee;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.fileList__delete {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  padding: 4px;
  width: 36px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  outline: none;
  cursor: pointer;
}
.fileList__delete:hover {
  background: rgba(0, 0, 0, 0.3);
}
.fileListLink {
  color: #006699;
}
.fileListLink:hover {
  color: #00aaff;
}

.installerCardContainer {
  position: relative;
  background: #fff;
}
.installerCardContainer .dualPointGraphContainer {
  top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.installerCardContainer .installerCardDetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 120px 1fr 1fr 1fr;
}
.installerCardContainer .installerCardDetails .installerCardDetail {
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  font-weight: bold;
}
.installerCardContainer .installerCardDetails .installerCardDetail h2 {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  margin-bottom: 0.3rem;
  font-weight: normal;
}
.installerCardContainer .installerCardDetails .installerCardDetail--blue {
  color: #0d2a8a;
}
.installerCardContainer .installerCardDetails .installerCardDetail--red {
  color: #ff0000;
}
.installerCardContainer .installerCardDetails .installerCardDetail--orange {
  color: #FF8A00;
}
.installerCardContainer .installerCardDetails .installerCardDetail--yellow {
  color: #ffc72c;
}
.installerCardContainer .installerCardProfile {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  background: none;
  margin: 0 auto;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  width: auto;
}
.installerCardContainer .installerCardProfile h1 {
  color: #0d2a8a;
  margin: 0 0 1rem 0;
  font-weight: normal;
  font-size: 2.2rem;
}
.installerCardContainer .installerCardProfile div {
  color: #006699;
  font-size: 1.2rem;
}
.installerCardContainer .installerCardButton {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  background: #0d2a8a;
  border-radius: 1000px;
  border: none;
  color: rgba(255, 255, 255, 0.95);
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: -12.5px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 3px 6px 3px rgba(60, 64, 67, 0.16);
}
.installerCardContainer .installerCardButton--dashboard {
  left: 2rem;
}
.installerCardContainer .installerCardButton--actionPlan {
  right: 2rem;
}
.installerCardContainer--fixed {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 3px 6px 3px rgba(60, 64, 67, 0.16);
}
.installerCardContainer--fixed .dualPointGraphContainer {
  top: 10px;
}
.installerCardContainer--fixed .installerCardDetails {
  margin-top: 2.2rem;
}
.installerCardContainer--fixed .installerCardProfile {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.installerCardContainer--fixed .installerCardProfile h1 {
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
}
.installerCardContainer--fixed .installerCardProfile div {
  font-size: 1.2rem;
}
.installerCardContainer--dark {
  box-shadow: none;
  background: linear-gradient(149.61deg, rgba(0, 51, 102, 0.2) -0.52%, rgba(0, 51, 102, 0.08) 100%);
  border: none;
  margin-top: 0.5rem;
}
.installerCardContainer--dark .installerCardDetails {
  grid-template-columns: 1fr 1fr 120px 1fr 1fr;
}
.installerCardContainer--dark .installerCardDetail h2 {
  color: #5B91C8 !important;
}
.installerCardContainer--dark .installerCardDetail--blue {
  color: #0080FF !important;
}
.installerCardContainer--dark .installerCardProfile h1 {
  color: #0080FF !important;
}
.installerCardContainer--dark .installerCardProfile div {
  color: #5B91C8 !important;
}
@media print {
  .installerCardContainer--fixed {
    box-shadow: none;
    top: 0;
  }
}

.contentContainer--releaseNotes {
  font-size: 1.8rem;
}
.contentContainer--releaseNotes h2 {
  text-align: center;
  margin: 4rem 0;
  font-size: 3rem;
}
.contentContainer--releaseNotes h3 {
  color: rgba(0, 0, 0, 0.75);
  font-size: 3rem;
}
.contentContainer--releaseNotes h5 {
  text-align: center;
  margin-top: -2.5rem;
  margin-bottom: 4rem;
}
.contentContainer--releaseNotes .releaseNotes__release:not(:first-of-type) {
  margin-top: 8rem;
}
.contentContainer--releaseNotes .releaseNotes__bugfix {
  line-height: 24px;
}
.contentContainer--releaseNotes .releaseNotes__bugfix i {
  vertical-align: bottom;
  color: #33A532;
  margin-right: 1rem;
}

body {
  overflow: auto !important;
}
.unauthorizedContainer {
  position: relative;
  height: 100%;
}
.unauthorizedContainer .unauthorizedContainerPadding {
  padding: 2rem;
}
.unauthorizedContainer .unauthorizedContent {
  width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.unauthorizedContainer .unauthorizedContent .unauthorizedLogo {
  margin: 0 0 6rem 0;
  text-align: center;
}
.unauthorizedContainer .unauthorizedContent .unauthorizedLogo img {
  max-height: 60px;
}
.unauthorizedContainer h1 {
  color: rgba(255, 255, 255, 0.95);
  font-weight: normal;
}
.unauthorizedContainer .input-container {
  border-color: none;
  background: rgba(0, 0, 0, 0.25);
}
.unauthorizedContainer .input-container .label {
  color: rgba(255, 255, 255, 0.95);
}
.unauthorizedContainer .input-container input {
  color: rgba(255, 255, 255, 0.95);
}
.unauthorizedContainer .input-container.input-focus .label,
.unauthorizedContainer .input-container.input-filled .label {
  color: rgba(179, 179, 179, 0.95);
}
.unauthorizedContainer .submitButton {
  background: #F5C211;
  color: #000000;
}
.unauthorizedContainer .submitButton:hover {
  background: #ca9f09;
}
.unauthorizedContainer .passwordTest {
  color: #cce5ff;
  line-height: 20px;
  margin: 0.5rem 0;
  word-wrap: break-word;
}
.unauthorizedContainer .passwordTest i {
  font-size: 21px;
}
.unauthorizedContainer .passwordTest--passed i {
  color: #33A532;
}
.unauthorizedContainer .passwordTest--failed i {
  color: rgba(0, 0, 0, 0.4);
}
.unauthorizedContainer .languageButton {
  border: none;
  background: none;
  outline: none;
  float: right;
  margin-top: 12px;
  text-decoration: underline;
  color: #00aaff;
  cursor: pointer;
  padding: 0px;
}
.unauthorizedContainer .errorMessageContainer {
  background-color: #FFCACA !important;
}
.unauthorizedContainer .forgotPasswordLink {
  color: #00aaff;
  display: inline-block;
  position: relative;
  top: -10px;
}
.unauthorizedContainer .forgotPasswordLink:hover,
.unauthorizedContainer .languageButton:hover {
  color: #0088cc;
}
@media screen and (max-width: 35.5em) {
  .unauthorizedContainer .unauthorizedContent {
    width: 100%;
  }
}

.card-item {
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  font-weight: normal;
  text-align: left;
}
.card-item h2 {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  margin-bottom: 0.3rem;
  font-weight: normal;
}

.noData {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 4rem 8rem;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  line-height: 3rem;
}
.noDataExtended {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 8rem 0;
  text-align: center;
  letter-spacing: 0.02em;
}
.noDataExtended .noDataExtendedHelper {
  font-size: 2rem;
  color: #0d2a8a;
  margin-bottom: 0.5rem;
}
.noDataExtended .noDataExtendedSubHelper {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.5);
}
.noDataExtended button,
.noDataExtended a {
  width: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 0 auto;
  margin-top: 2.5rem;
  display: inline-block;
}

/* Variable definitions */
.input-container {
  display: block;
  border-radius: 4px;
  margin: 0 0 1.5rem 0;
  position: relative;
  height: 4.5rem;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
label {
  display: block;
}
label .label {
  font-size: 1.4rem;
  position: absolute;
  line-height: 4.5rem;
  transition: all 0.1s ease-out;
  color: rgba(0, 0, 0, 0.65);
}
.input-container.input-focus .label,
.input-container.input-filled .label {
  top: 0px;
  font-size: 1.2rem;
  line-height: 2.4rem;
  transition: all 0.1s ease;
}
.input-container.input-focus {
  border: 1px solid #003366;
}
.required-hint:after {
  content: " *";
  color: #ff0000;
  position: absolute;
  margin: 0px 0px 0px 80%;
  font-size: 1.2em;
  top: 0.5rem;
  right: 0.8rem;
}
select,
input {
  position: relative;
  background: none;
  border: none;
  outline: none;
}
select {
  width: 100%;
  font-size: 1.6rem;
  height: 100%;
}
input {
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  text-indent: 1.5rem;
}
.input-search {
  max-width: 40%;
  position: absolute;
  right: 2px;
  top: 2px;
  line-height: 1rem;
}
@media screen and (min-width: 48em) {
  .input-search {
    max-width: 20%;
  }
}

.btn {
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 1.5rem 0px;
  text-align: center;
  width: 100%;
  outline: none;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.6rem;
  -webkit-appearance: none;
          appearance: none;
}
.btn-yellow {
  background: #ffc72c;
  color: #0d2a8a;
}
.btn-secondary {
  color: rgba(0, 0, 0, 0.9);
}
.btn-primary {
  background: #0d2a8a;
}
.btn-primary:hover {
  background: #006699;
}
.btn-success {
  background: #33A532;
}
.btn-success:hover {
  background: #45c644;
}
.btn-arrow {
  background-color: rgba(255, 255, 255, 0.95);
  display: inline;
  max-width: 60px;
  padding: 1rem;
}
.btn-arrow i {
  color: #0d2a8a;
}
.btn-agree {
  padding: 2rem 5rem;
  width: auto;
  margin: 0 auto;
  font-size: 2.1rem;
  background: #33A532;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.btn-agree:hover {
  background: #277e26;
}
.btn:disabled {
  background: #A7A7A7;
  cursor: not-allowed;
  color: #fff;
}
button.clear {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  border-radius: 1000px;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  background: #eee;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 0.5rem 1rem;
}
.btn-link {
  width: auto;
  padding: 0px;
  margin: 0px;
  background: none;
  color: #0d2a8a;
  text-decoration: underline;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-top: 3rem;
}

.datepickerContainer {
  position: relative;
  min-height: 40px;
}
.datepickerContainer .datepicker--top {
  bottom: calc(130%);
}
.datepickerContainer .datepicker {
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.9);
  z-index: 100;
  position: absolute;
  background: #fff;
  left: 0;
}
.datepickerContainer .datepicker .datepickerTriangle {
  width: 0;
  height: 0;
  left: 33px;
  position: absolute;
}
.datepickerContainer .datepicker .datepickerTriangle--top {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #eee;
  top: -8px;
}
.datepickerContainer .datepicker .datepickerTriangle--bottom {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  bottom: -8px;
}
.datepickerContainer .datepicker .datepickerHeader {
  background: #eee;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
  font-size: 1.2rem;
  border-radius: 4px 4px 0 0;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerMonth {
  text-align: center;
  line-height: 3rem;
  font-weight: bold;
  font-size: 1.3rem;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse {
  border: none;
  outline: none;
  background: none;
  padding: 0px;
  margin: 0px;
  position: absolute;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse i {
  line-height: 3rem;
  width: 3rem;
  height: 3rem;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse:hover {
  color: rgba(0, 0, 0, 0.7);
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse--prev {
  left: 0;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerTraverse--next {
  right: 0;
}
.datepickerContainer .datepicker .datepickerHeader .datepickerDaysOfWeek .datepickerDayOfWeek {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  padding: 0.3rem;
}
.datepickerContainer .datepicker .datepickerBody {
  padding: .4rem;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay {
  display: inline-block;
  vertical-align: bottom;
  background: none;
  border: none;
  padding: 0.3rem;
  outline: none;
  position: relative;
  color: rgba(0, 0, 0, 0.3);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay .datepickerDay--padding {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay--currentMonth {
  color: rgba(0, 0, 0, 0.9);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek button.datepickerDay {
  cursor: pointer;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek button.datepickerDay .datepickerDay--padding {
  border-radius: 50%;
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek button.datepickerDay:hover:not(.datepickerDay--selected) .datepickerDay--padding {
  background: rgba(0, 0, 0, 0.1);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay--today .datepickerDay--padding {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.1);
}
.datepickerContainer .datepicker .datepickerBody .datepickerWeek .datepickerDay--selected .datepickerDay--padding {
  font-weight: bold;
  background: #003366;
  color: rgba(255, 255, 255, 0.95);
}

.submitButton {
  display: block;
  border-radius: 4px;
  border: none;
  padding: 1.5rem 3rem;
  background: #003366;
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  font-family: inherit;
  font-size: 1.6rem;
  text-decoration: none;
  margin-bottom: 2rem;
}
.submitButton--fullWidth {
  width: 100%;
}
.submitButton--yellow {
  background: #F5C211;
  color: #382c02;
}
.submitButton--yellow:hover {
  background: #e3b20a !important;
}
.submitButton--green {
  background: #33A532;
  color: rgba(255, 255, 255, 0.95);
}
.submitButton--green:hover {
  color: rgba(255, 255, 255, 0.95);
  background: #277e26 !important;
}
.submitButton--red {
  background: #ff0000;
  color: rgba(255, 255, 255, 0.95);
}
.submitButton--red:hover {
  color: rgba(255, 255, 255, 0.95);
  background: #cc0000 !important;
}
.submitButton:hover {
  background: #006699;
}
.submitButton:disabled {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.5);
}
.submitButton--search {
  display: inline;
  float: right;
  padding: 8px 8px;
  background: rgba(255, 255, 255, 0.95);
  border: none;
  cursor: pointer;
  position: relative;
  top: -6rem;
  left: -2px;
}
.submitButton--search i {
  color: rgba(0, 0, 0, 0.9);
}
.submitButton--search:hover {
  background: rgba(255, 255, 255, 0.95);
}
.submitButton--search:disabled {
  background: rgba(255, 255, 255, 0.95);
}

.cardInstaller {
  text-align: center;
  padding: 3rem 0;
  margin: -2rem -2rem 0 -2rem;
  background: #fafafa;
  position: relative;
  border-bottom: 1px solid #eee;
}
.cardInstaller h1 {
  font-size: 2.4rem;
  color: #0d2a8a;
  font-weight: normal;
  margin: 0;
  letter-spacing: 0.02em;
  margin-bottom: 1rem;
}
.cardInstaller div.address {
  letter-spacing: 0.02em;
  color: #1646e7;
  font-size: 1.6rem;
}
.cardInstaller .directions {
  display: block;
  position: absolute;
  bottom: -1.9rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 36px;
  height: 36px;
  background: #0d2a8a;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18), 0 1px 3px 1px rgba(60, 64, 67, 0.36);
}
.cardInstaller .directions i {
  line-height: 36px;
  color: rgba(255, 255, 255, 0.95);
}
.cardHistory .history {
  display: grid;
  grid-template-columns: minmax(80px, 1fr) minmax(90px, 1fr) minmax(60px, 1fr) minmax(40px, 0.4fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
}
.cardHistory .history .dualPointGraphContainer {
  position: relative;
}
.cardHistory .history .cardHistoryDetail {
  text-align: left;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  font-weight: bold;
  padding-top: 1.2rem;
}
.cardHistory .history .cardHistoryDetail h2 {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  margin-bottom: 0.3rem;
  font-weight: normal;
}
.cardHistory .history .cardHistoryDetail--right {
  text-align: right;
}
.cardHistory .history .cardHistoryDetail--blue {
  color: #0d2a8a;
}
.cardHistory .history .cardHistoryDetail--red {
  color: #ff0000;
}
.cardHistory .history .cardHistoryDetail--orange {
  color: #FF8A00;
}
.cardHistory .history .cardHistoryDetail--yellow {
  color: #ffc72c;
}
.cardHistory .history .cardHistoryDivider {
  background: #eee;
  width: 1px;
  height: 40px;
  margin-top: 10px;
}

h1.actionPlan {
  text-align: center;
  font-weight: normal;
  color: #0d2a8a;
}
.returnToSurvey {
  background: #eee;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: #0d2a8a;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.pageNav {
  margin-top: 130px;
}
.pageNav button.pageTitle {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  background: none;
  cursor: pointer;
  text-align: left;
}
.pageNav button.pageTitle {
  padding: 2rem 0 0 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: #0d2a8a;
}
.pageNav button.pageTitle .pageCounter {
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  font-weight: bold;
  margin-bottom: 0.2rem;
  color: rgba(0, 0, 0, 0.6);
}
.pageNav button.pageTitle .sectionTitle {
  font-size: 1.5rem;
  display: inline;
  font-weight: normal;
}
.pageNav button.navigate {
  background: #eee;
  border-radius: 1000px;
  height: 36px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  width: auto;
  margin-top: 1.4rem;
}
.pageNav button.navigate i {
  vertical-align: bottom;
}
.pagesDisplay {
  position: fixed;
  top: 198px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 3rem 2rem;
}
.pagesDisplay .card {
  margin: 0px;
  max-height: 40px;
  text-align: center;
}
.actionPlanActions {
  text-align: right;
}
.actionPlanActions button {
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  margin: 0 0 0 1.5rem;
  border: none;
  background: none;
  color: rgba(0, 0, 0, 0.5);
}
.installerHeader {
  position: fixed;
  top: 80px;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  margin: -2rem -2rem 0 -2rem;
  background: #fafafa;
  border-bottom: 1px solid #eee;
  z-index: 2;
}
.installerHeader h1 {
  font-size: 1.7rem;
  color: #0d2a8a;
  font-weight: normal;
  margin: 0;
  letter-spacing: 0.02em;
  margin-bottom: 0.5rem;
}
.installerHeader div.address {
  letter-spacing: 0.02em;
  color: #1646e7;
  font-size: 1.2rem;
}
.installerHeader .directions {
  display: block;
  position: absolute;
  bottom: -1.9rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 36px;
  height: 36px;
  background: #0d2a8a;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18), 0 1px 3px 1px rgba(60, 64, 67, 0.36);
}
.installerHeader .directions i {
  line-height: 36px;
  color: rgba(255, 255, 255, 0.95);
}
.actionPlanMargin--sm {
  margin-top: 160px;
}
.actionPlanMargin--lg {
  margin-top: 200px;
}
.actionPlanItemBottom {
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1.5rem;
}
button.edit {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  background: #ddd;
  border-radius: 4px;
  text-align: center;
  line-height: 24px;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
button.edit i {
  vertical-align: bottom;
  margin-right: 0.5rem;
}
button.edit:hover {
  background: #c4c4c4;
}
.actionPlanItemComplete {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  background: #33A532;
  color: rgba(255, 255, 255, 0.95);
  line-height: 24px;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}
.actionPlanItemComplete i {
  vertical-align: bottom;
  margin-right: 0.5rem;
}
.actionPlanItemComplete:hover {
  background: #277e26;
}
@media print {
  .hideOnPrint {
    display: none;
  }
  .displayOnPrint {
    display: block;
  }
  .main {
    margin: 0 !important;
  }
  .installerCardContainer {
    position: absolute;
  }
  .actionPlanMargin {
    margin-top: 160px;
  }
}

.priorityDividerContainer {
  border-bottom: 1px solid #eee;
  position: relative;
  margin-bottom: 6rem;
  page-break-inside: avoid;
  padding-top: 6rem;
}
.priorityDividerContainer .priorityText {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  padding: 0 1rem;
  font-size: 2rem;
  text-transform: uppercase;
  page-break-inside: avoid;
}
.priorityDividerContainer--high {
  border-color: #ff0000;
}
.priorityDividerContainer--high .priorityText {
  color: #ff0000;
}
.priorityDividerContainer--medium {
  border-color: #FF8A00;
}
.priorityDividerContainer--medium .priorityText {
  color: #FF8A00;
}
.priorityDividerContainer--low {
  border-color: #ffc72c;
}
.priorityDividerContainer--low .priorityText {
  color: #ffc72c;
}
.prioritySectionTitle {
  text-align: center;
  color: #0d2a8a;
}

.tabSelectorContainer {
  border: 1px solid #0d2a8a;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  height: 30px;
}
.tabSelectorContainer .tabSelectorButton {
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  margin: 0px;
  padding: 0px 2rem;
  line-height: 30px;
  vertical-align: top;
  cursor: pointer;
}
.tabSelectorContainer .tabSelectorButton--active {
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
}
.tabSelectorContainer .tabSelectorButton--inactive {
  color: #0d2a8a;
}

.refreshContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.2rem 0;
  text-align: center;
  background: #fff3cd;
  border-bottom: 1px solid #ffeeba;
  z-index: 1000;
  color: #856404;
  letter-spacing: 0.02em;
  font-size: 1.3rem;
}
.refreshContainer button {
  outline: none;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
}
.refreshContainer button:hover {
  color: #b78905;
}

.offlineSyncContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.95);
}
.offlineSyncContainer h1 {
  color: rgba(255, 255, 255, 0.95);
}
.offlineSyncContainer .offlineSyncItem {
  line-height: 24px;
  margin: 1rem;
}
.offlineSyncContainer .offlineSyncItem i {
  vertical-align: bottom;
}
.offlineSyncContainer .offlineSyncItem--false i {
  -webkit-animation: sync 2s infinite linear;
          animation: sync 2s infinite linear;
}
@-webkit-keyframes sync {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes sync {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

.waitingToSync {
  position: absolute;
  right: 5.5rem;
  top: 50%;
  height: 24px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.waitingToSync .syncCountBubble {
  position: absolute;
  background: #ff0000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  top: -6px;
  right: -6px;
}

.breadcrumbContainer {
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #0d2a8a;
  position: relative;
  width: 100%;
}
.breadcrumbContainer:hover .breadcrumbClose {
  display: block;
}
.breadcrumb {
  padding: 20px 30px 20px 20px;
  position: relative;
  height: 40px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.95);
}
.breadcrumb .breadcrumbTitle {
  color: #66ccff;
  font-size: 1.2rem;
}
.breadcrumb.breadcrumb--leftPad {
  border-top: 2px solid rgba(255, 255, 255, 0.95);
}
.breadcrumbArrow {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-14px);
          transform: translateX(-14px);
  top: -2px;
}
.breadcrumbArrow:before,
.breadcrumbArrow:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 14px solid transparent;
}
.breadcrumbArrow:before {
  border-top-color: #0d2a8a;
  z-index: 2;
  top: -3px;
}
.breadcrumbArrow:after {
  border-top-color: rgba(255, 255, 255, 0.95);
  z-index: 1;
}
.breadcrumbClose {
  background: #fff;
  position: absolute;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border: none;
  outline: none;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  margin: 0;
  padding: 3px;
  height: 30px;
  cursor: pointer;
}
.breadcrumbClose i {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.breadcrumbClose:hover {
  background: #e6e6e6;
}
@media screen and (min-width: 48em) {
  .breadcrumbContainer {
    width: auto;
  }
  .breadcrumb {
    float: left;
    padding: 10px 30px 10px 20px;
    overflow: hidden;
  }
  .breadcrumb.breadcrumb--leftPad {
    padding-left: 50px;
    margin-left: -20px;
    border: none;
  }
  .breadcrumbArrow {
    position: absolute;
    left: 0px;
    top: -4px;
    -webkit-transform: none;
            transform: none;
  }
  .breadcrumbArrow:before,
  .breadcrumbArrow:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    border: 34px solid transparent;
  }
  .breadcrumbArrow:before {
    border-left-color: #0d2a8a;
    z-index: 2;
    left: -4px;
    top: auto;
    top: initial;
  }
  .breadcrumbArrow:after {
    border-left-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
  }
  .breadcrumbClose {
    background: #fff;
    position: absolute;
    right: -15px;
    bottom: auto;
    bottom: initial;
    left: auto;
    left: initial;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: none;
    outline: none;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    margin: 0;
    padding: 3px;
    height: 30px;
    cursor: pointer;
  }
  .breadcrumbClose i {
    -webkit-transform: none;
            transform: none;
  }
}

/* NOTE: This line below is required for MD icons to appear */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
html {
  font-size: 62.5%;
}
root,
html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #0d2a8a;
  color: rgba(0, 0, 0, 0.9);
  overflow-y: scroll;
}
h1,
h2 {
  color: #0d2a8a;
  margin: 0;
  font-weight: normal;
}
h2 {
  margin-top: 2rem;
}
.material-icons {
  -webkit-font-feature-settings: "liga" 1;
          font-feature-settings: "liga" 1;
}
/* Start utilities */
.hidden,
.grid.hidden {
  display: none;
}
.text-center {
  text-align: center;
}
.f-right {
  float: right;
}
.inline-link {
  color: #0d2a8a;
}
.full-width {
  min-width: 100%;
}
/* NOTE: The 20 in the naming here is to represent 20 as the pixel value in case more utility classes want to leverage this convention */
.pad-t-20 {
  padding-top: 1.25em;
}
.pad-b-20 {
  padding-bottom: 1.25em;
}
.push-b-0 {
  margin-bottom: 0;
}
/* End utilities */
/* Start header bar */
header {
  height: 60px;
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  font-size: 2em;
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0px auto;
  z-index: 100;
  text-align: center;
}
.logo {
  height: 36px;
  margin: 12px 2rem;
}
/* End header bar */
/* Start main content area */
.main {
  /* NOTE: This syntax is shorthand for top value of 0, side values of 20px and bottom value of 10px */
  padding: 2rem;
  background: #fff;
  box-sizing: border-box;
  margin-top: 60px;
  font-size: 1.4rem;
  position: relative;
}
.contentContainer {
  max-width: 1024px;
  margin: 0 auto;
}
.contentContainer .installer-list {
  padding-top: 2rem;
}
.contentContainer--login,
.contentContainer--forgot {
  max-width: 640px;
}
.primary--heading {
  color: #0d2a8a;
}
.linked-card--container {
  text-decoration: none;
}
/* Start dashboard */
.dashboard--row {
  margin-bottom: 10px;
}
.dashboard--row .dashboard--row__title {
  background: #006699;
  color: rgba(255, 255, 255, 0.95);
  padding: 0.5em;
  position: relative;
  top: 45px;
}
.dashboard--row .dashboard--row__title h2 {
  font-weight: 100;
  margin: 0;
}
.dashboard--row .dashboard--row__title .expander-menu {
  color: rgba(255, 255, 255, 0.95);
  position: relative;
  top: -3.5rem;
}
.dashboard--row .dashboard--row__content {
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  min-height: 100px;
  /* These rules are for horizontal scrolling containers */
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  min-width: 100%;
  -webkit-overflow-scrolling: touch;
  /* Horizontal row info container (data column) */
}
.dashboard--row .dashboard--row__content .dashboard--row__info {
  display: inline-block;
  max-width: 20%;
  padding: 3rem 6rem;
  text-align: center;
}
.dashboard--row .dashboard--row__content .dashboard--row__info .primary--info__number {
  font-size: 1.6rem;
}
#long-menu p {
  color: #0d2a8a;
  font-size: 1.2rem;
}
/* End dashboard */
/* Start survey list */
.contentContainer--survey-list a {
  text-decoration: none;
}
.contentContainer--survey-list .expander-menu {
  position: absolute;
  right: 2rem;
  margin-top: -5.75rem;
}
.survey-draft {
  color: #A7A7A7;
}
.survey-completed {
  color: #33A532;
}
/* End survey list */
/* Start reports */
.contentContainer--reports {
  /* NOTE: This is for when all columns should be displayed */
}
.contentContainer--reports .reports-table .table-headings--container th {
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  text-align: left;
}
.contentContainer--reports .reports-table .table-headings--container th:nth-of-type(2) {
  display: none;
}
.contentContainer--reports .reports-table tr > th,
.contentContainer--reports .reports-table tbody > tr {
  width: calc(100% / 5);
}
.contentContainer--reports .reports-table td:nth-of-type(2) {
  display: none;
}
.contentContainer--reports .reports-table tbody td {
  padding: 1rem;
}
.contentContainer--reports .full-table .table-headings--container th:nth-of-type(2) {
  display: table-cell;
}
.contentContainer--reports .full-table tr > th,
.contentContainer--reports .full-table tbody > tr {
  width: calc(100% / 6);
}
.contentContainer--reports .full-table td:nth-of-type(2) {
  display: table-cell;
}
.contentContainer--reports .reports-grid .card-title-container {
  /* NOTE: This rule is to help provide a consistent card heading height */
}
.contentContainer--reports .reports-grid .card-title-container h3 {
  min-height: 50px;
}
/* End reports */
/* End main content area */
/* Start media queries */
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .grid {
      display: grid;
      grid-gap: 3rem;
    }
    .grid-gap-sm {
      grid-gap: 1rem;
    }
    .grid-1-1 {
      grid-template-columns: 1fr 1fr;
    }
    .grid-1-2 {
      grid-template-columns: 1fr 2fr;
    }
    .grid-one-third {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-1-3 {
      grid-template-columns: 1fr 3fr;
    }
    .grid-2-1 {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
    }
    .dashboard--row {
      max-width: 728px;
    }
    .survey-category--container {
      position: relative;
      top: 2px;
    }
    .survey-category--container .survey-category-text {
      color: #0d2a8a;
      font-size: 1.6rem;
      vertical-align: super;
    }
    .survey-category--container .btn-arrow:last-of-type {
      float: right;
    }
    .vertical-tabs-container {
      background-color: rgba(255, 255, 255, 0.95);
      min-height: 100%;
      width: 25%;
    }
    .vertical-tabs-container .vertical-tab-link {
      background-color: #c4c4c4;
      border: 1px solid rgba(255, 255, 255, 0.95);
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;
      display: block;
      outline: none;
      padding: 1rem;
      text-align: left;
      transition: 0.3s;
      width: 100%;
    }
    .vertical-tabs-container .vertical-tab-link:hover {
      background-color: #1138b9;
      color: rgba(0, 0, 0, 0.9);
    }
    .vertical-tabs-container .vertical-tab-link.active {
      background-color: #0d2a8a;
      color: rgba(255, 255, 255, 0.95);
    }
    .vertical-tabs-container .vertical-tab-link .survey-subcategory-text {
      font-size: 1.6rem;
    }
    .rwt__tablist[aria-orientation="vertical"] {
      border-right: none !important;
    }
    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
      display: none;
    }
    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="false"]:hover {
      background-color: #1138b9;
      color: rgba(0, 0, 0, 0.9);
    }
    .rwt__tablist[aria-orientation="vertical"] .rwt__tab:focus {
      background-color: #0d2a8a;
      color: rgba(255, 255, 255, 0.95);
    }
    .survey-questions--container {
      background: rgba(255, 255, 255, 0.95);
      border-left: none;
      min-height: 100%;
      min-width: 75%;
      margin-left: -1rem;
    }
    .survey-questions--container .survey-question {
      padding: 0 2rem 4rem 2rem;
    }
    .survey-questions--container .survey-question label {
      margin-left: 0px;
    }
    .survey-questions--container .survey-question .action-plan--container,
    .survey-questions--container .survey-question .action-plan--label {
      position: relative;
      top: -5px;
    }
    .survey-questions--container .survey-question .radio {
      display: inline-block;
      position: relative;
      top: 2px;
    }
    .survey-questions--container .survey-question .radio label {
      position: relative;
      padding-right: 5px;
    }
    .survey-questions--container .switch {
      border: none;
      margin-bottom: 0px;
      padding-left: 0px;
    }
    .survey-questions--container .switch .toggle__wrapper {
      margin-left: 0px;
    }
    .survey-questions--container .binary-yes-label {
      position: relative;
      top: -3.25rem;
      left: 110px;
    }
    .survey-questions--container .notes-container .textarea {
      border: none;
      padding-right: 0.75rem;
      padding-left: 0px;
    }
    .survey-questions--container .notes-container--hidden {
      opacity: 0;
      max-height: 0px;
      transition: all 0.5s linear;
      overflow: hidden;
    }
    .survey-questions--container .notes-container--visible {
      opacity: 1;
      max-height: 300px;
      transition: all 0.5s linear;
    }
  }
  @media screen and (min-width: 64em) {
    .dashboard--row {
      max-width: 984px;
    }
    .contentContainer--reports .reports-table .table-headings--container th:nth-of-type(2) {
      display: table-cell;
    }
    .contentContainer--reports .reports-table tr > th,
    .contentContainer--reports .reports-table tbody > tr {
      width: calc(100% / 6);
    }
    .contentContainer--reports .reports-table td:nth-of-type(2) {
      display: table-cell;
    }
  }
  @media screen and (min-width: 64.063em) {
    .dashboard--row {
      max-width: 1024px;
    }
    .contentContainer--survey-list .expander-menu {
      position: relative;
    }
  }
}

