.submitButton {
  display: block;
  border-radius: 4px;
  border: none;
  padding: 1.5rem 3rem;
  background: #003366;
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  font-family: inherit;
  font-size: 1.6rem;
  text-decoration: none;
  margin-bottom: 2rem;
}
.submitButton--fullWidth {
  width: 100%;
}
.submitButton--yellow {
  background: #F5C211;
  color: #382c02;
}
.submitButton--yellow:hover {
  background: #e3b20a !important;
}
.submitButton--green {
  background: #33A532;
  color: rgba(255, 255, 255, 0.95);
}
.submitButton--green:hover {
  color: rgba(255, 255, 255, 0.95);
  background: #277e26 !important;
}
.submitButton--red {
  background: #ff0000;
  color: rgba(255, 255, 255, 0.95);
}
.submitButton--red:hover {
  color: rgba(255, 255, 255, 0.95);
  background: #cc0000 !important;
}
.submitButton:hover {
  background: #006699;
}
.submitButton:disabled {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.5);
}
.submitButton--search {
  display: inline;
  float: right;
  padding: 8px 8px;
  background: rgba(255, 255, 255, 0.95);
  border: none;
  cursor: pointer;
  position: relative;
  top: -6rem;
  left: -2px;
}
.submitButton--search i {
  color: rgba(0, 0, 0, 0.9);
}
.submitButton--search:hover {
  background: rgba(255, 255, 255, 0.95);
}
.submitButton--search:disabled {
  background: rgba(255, 255, 255, 0.95);
}
