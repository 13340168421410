.card {
  padding: 1.2rem 1.5rem;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
  background: none;
  margin: 2rem 0;
  display: block;
  border: 1px solid #eee;
  outline: none;
  position: relative;
  transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1), width 235ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 5px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  page-break-inside: avoid;
}
.card .cardPointTitleContainer {
  text-align: center;
  text-transform: uppercase;
  font-size: 5.6rem;
  margin: 3rem 0 6rem 0;
  color: #0d2a8a;
}
.card .cardPointTitleContainer .cardPointTitle {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
}
.card .cardPriorityTitleContainer {
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}
.card .cardPriorityTitleContainer .cardPriorityTitle {
  background: #eee;
  margin: -1.3rem -1.6rem 0 -1.6rem;
  border-radius: 5px 5px 0 0;
  padding: 1rem 0;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
}
.card .cardPriorityTitleContainer .cardPriorityItemCount {
  font-size: 7rem;
  margin-top: 2rem;
}
.card .cardPriorityTitleContainer--high .cardPriorityTitle {
  background: #ff0000;
  color: #700000;
}
.card .cardPriorityTitleContainer--high .cardPriorityItemCount {
  color: #ff0000;
}
.card .cardPriorityTitleContainer--medium .cardPriorityTitle {
  background: #FF8A00;
  color: #703d00;
}
.card .cardPriorityTitleContainer--medium .cardPriorityItemCount {
  color: #FF8A00;
}
.card .cardPriorityTitleContainer--low .cardPriorityTitle {
  background: #ffc72c;
  color: #9c7300;
}
.card .cardPriorityTitleContainer--low .cardPriorityItemCount {
  color: #ffc72c;
}
.card .cardDivider {
  border-top: 1px solid #eee;
  margin: 2rem -1.6rem;
  position: relative;
}
.card .cardDivider .cardDividerTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  padding: 0 0.8rem;
}
.card.card--goldStandard {
  border: 1px solid #ffc72c;
}
.card.card--goldStandard .goldStandardIcon {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
}
.card.card--goldStandard .goldStandardIcon svg {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}
.card.card--goldStandard .cardPointTitleContainer {
  color: #ffc72c;
}
.card.card--goldStandard .cardDivider {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
a.card:hover,
button.card:hover {
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 4px 12px 1px rgba(60, 64, 67, 0.16);
}
.card--dark {
  box-shadow: none;
  background: linear-gradient(149.61deg, rgba(0, 51, 102, 0.2) -0.52%, rgba(0, 51, 102, 0.08) 100%);
  border: none;
  margin-top: 0.5rem;
}
.cardStatPoints {
  text-align: center;
  font-size: 2.6rem;
  margin: 1rem 0;
}
.cardStatPoints--overall {
  color: #0080FF;
}
.cardStatPoints--goldStandard {
  color: #ffc72c;
}
.cardStatPoints--small {
  font-size: 1.2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  color: #A0A0A0;
}
.cardStatPoints--small .cardStatPercentage {
  position: relative;
  top: auto;
}
.cardStatPoints--small.goldStandard {
  top: 0;
  left: 4.5rem;
  color: #ffc72c;
}
.cardStatPoints--small.overall {
  top: 0;
  right: 0rem;
  left: auto;
  color: #0080FF;
}
.card--button {
  cursor: pointer !important;
}
.actionPlanCardQuestion--high {
  color: #ff0000;
}
.actionPlanCardQuestion--medium {
  color: #FF8A00;
}
.actionPlanCardQuestion--low {
  color: #ffc72c;
}
.cardPriorityHighlight {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  border-radius: 5px 0 0 5px;
  z-index: 1;
}
.cardPriorityHighlight--high {
  background: #ff0000;
}
.cardPriorityHighlight--medium {
  background: #FF8A00;
}
.cardPriorityHighlight--low {
  background: #ffc72c;
}
