.nav__toggle {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 0 -1rem;
}
.nav__toggle i {
  color: #fff;
}
.nav__container {
  background: #0d2a8a;
  padding: 1rem 2rem;
  min-width: 240px;
  position: fixed;
  top: 6rem;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  opacity: 1;
  z-index: 1;
  transform: translateX(0);
  transition: transform 0.2s;
  text-align: center;
}
.nav__container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav__container ul li {
  display: block;
  text-align: left;
}
.nav__container ul li button {
  background: none;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.95);
  font-size: 1.6rem;
  padding: 1.5rem 0;
  cursor: pointer;
}
.nav__container ul li button:hover {
  color: rgba(204, 204, 204, 0.95);
}
.nav__container a:not(.button) {
  font-size: initial;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  line-height: 2em;
}
.nav__container a:hover {
  color: #006699;
}
.nav__container--hidden {
  transform: translateX(100%);
}
.semver {
  font-size: 1.1rem;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 0.5);
}
.nav__stats {
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  margin: 0 -1rem;
  margin-bottom: 10rem;
  z-index: 1000;
}
.nav__stats i {
  color: #fff;
}
.csvDownloadButton {
  left: auto;
  right: 2rem;
  top: -1rem;
}
.statsContainer {
  background: #001429;
  padding: 4rem 2rem 2rem 2rem;
  position: fixed;
  left: -100%;
  overflow-y: auto;
}
.statsContainer .statsContainerMaxWidth {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
}
.statsContainer h2 {
  font-size: 2rem;
  color: #a7c4e2;
  font-weight: normal;
  margin-top: 6rem;
  margin-bottom: 3rem;
}
.statsContainer h2 div {
  font-size: 1.4rem;
  color: #5B91C8;
  margin-bottom: 0.5rem;
}
.statsContainer h3 {
  font-size: 1.4rem;
  font-weight: normal;
  color: #5B91C8;
  text-align: left;
}
.statsContainer--expanded {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.reportDisplaySwitch {
  background: #0d2a8a;
  border: none;
  margin: 0;
  padding: 0.5rem 1rem;
  outline: none;
  appearance: none;
  border-radius: 5px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.95);
  float: right;
}
.reportDisplaySwitch i {
  vertical-align: bottom;
  margin-left: 1rem;
}
.bdsCardHeader {
  text-align: left;
  font-size: 1.4rem;
  color: #5B91C8;
}
.bdsCardStatsContainer {
  float: right;
  font-size: 1.2rem;
}
.bdsCardStatsContainer .bdsCardStats {
  margin-left: 2rem;
}
.bdsCardStatsContainer .bdsCardStats--goldStandard {
  color: #ffc72c;
}
.bdsCardStatsContainer .bdsCardStats--onTrack {
  color: #0080FF;
}
.bdsCardStatsContainer .bdsCardStats--installersSurveyed {
  color: #2FFF69;
}
.bdsCardStatsContainer .bdsCardStats--revisits {
  color: #A32FFF;
}
.progressBarContainer {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 1rem;
}
.progressBarContainer .progressBar {
  height: 5px;
  position: absolute;
  left: 0;
  top: 0;
  background: #0080FF;
  width: 0px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.progressBarContainer .progressBar--goldStandard {
  background: #ffc72c;
}
.progressBarContainer .progressBar--onTrack {
  background: #0080FF;
}
.progressBarContainer .progressBar--installersSurveyed {
  background: #2FFF69;
}
.progressBarContainer .progressBar--revisits {
  background: #A32FFF;
}
.tabSelectorContainer {
  border: 1px solid #0d2a8a;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  height: 30px;
}
.tabSelectorContainer .tabSelectorButton {
  border: none;
  background: none;
  outline: none;
  appearance: none;
  margin: 0px;
  padding: 0px 2rem;
  line-height: 30px;
  vertical-align: top;
  cursor: pointer;
}
.tabSelectorContainer .tabSelectorButton--active {
  background: #0d2a8a;
  color: rgba(0, 0, 0, 0.9);
}
.tabSelectorContainer .tabSelectorButton--inactive {
  color: #5B91C8;
}
.analytic .card,
.breakdownCard.card {
  border: 1px solid rgba(0, 0, 0, 0);
}
.analytic--selected .card,
.breakdownCard--selected.card {
  border: 1px solid #0080FF;
}
.clearStat {
  border: 1px solid #0080FF;
  border-radius: 5px;
  background: #0080FF;
  color: rgba(0, 0, 0, 0.9);
  outline: none;
  appearance: none;
  margin: 0px;
  padding: 3px 1rem;
  line-height: 30px;
  vertical-align: top;
  cursor: pointer;
  line-height: 24px;
  float: right;
  max-width: 1024px;
}
.clearStat i {
  vertical-align: bottom;
  margin-left: 1rem;
}
.clearStat--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 3rem 1rem 1rem 1rem;
  background: linear-gradient(to bottom, rgba(0, 20, 41, 0), #001429);
}
.clearStat--fixed button.clearStat {
  width: 100%;
  padding: 1.5rem 0;
  font-size: 1.8rem;
  float: none;
  margin-left: -1.5rem;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
}
.cardStatPercentage {
  font-size: 1.2rem;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: #A0A0A0;
  line-height: 24px;
}
.cardStatPercentage i {
  vertical-align: bottom;
  display: none;
}
.cardStatPercentage--increase {
  color: #2FFF69;
}
.cardStatPercentage--decrease {
  color: #FF2F2F;
}
