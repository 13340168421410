.priorityDividerContainer {
  border-bottom: 1px solid #eee;
  position: relative;
  margin-bottom: 6rem;
  page-break-inside: avoid;
  padding-top: 6rem;
}
.priorityDividerContainer .priorityText {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 0 1rem;
  font-size: 2rem;
  text-transform: uppercase;
  page-break-inside: avoid;
}
.priorityDividerContainer--high {
  border-color: #ff0000;
}
.priorityDividerContainer--high .priorityText {
  color: #ff0000;
}
.priorityDividerContainer--medium {
  border-color: #FF8A00;
}
.priorityDividerContainer--medium .priorityText {
  color: #FF8A00;
}
.priorityDividerContainer--low {
  border-color: #ffc72c;
}
.priorityDividerContainer--low .priorityText {
  color: #ffc72c;
}
.prioritySectionTitle {
  text-align: center;
  color: #0d2a8a;
}
